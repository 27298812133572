var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],staticClass:"language-selector has-line-height-1"},[_c('div',{class:{
			dropdown: true,
			active: _vm.dropdownOpen,
		}},[_c('button',{staticClass:"button lang-button is-size-14 dark-btn has-text-weight-normal",on:{"click":function($event){_vm.dropdownOpen = !_vm.dropdownOpen}}},[_c('img',{attrs:{"src":require('../assets/img/flags/' +
						_vm.$i18n.locale.split('-')[0].toLowerCase() +
						'.svg')}}),_c('span',{staticClass:"has-text-weight-medium lang-name"},[_vm._v(_vm._s(_vm.getLanguageParameter(_vm.$i18n.locale, "name")))]),_c('i',{staticClass:"fas fa-chevron-up arrow",class:{
					active: _vm.dropdownOpen,
				}})]),_c('div',{staticClass:"dropdown-items"},_vm._l((_vm.languages),function(language){return _c('div',{key:language.code,staticClass:"lang-item has-text-grey-faded",on:{"click":function () { return _vm.setLanguage(language.code); }}},[_c('img',{attrs:{"src":require('../assets/img/flags/' +
							language.code.toLowerCase() +
							'.svg')}}),_c('span',[_vm._v(_vm._s(language.name))])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }