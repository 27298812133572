<template>
	<footer class="footer">
		<div class="container">
			<!-- Menu Links -->
			<div
				v-show="!emptyFooter"
				class="columns is-centered has-text-left footer-content footer-flip"
			>
				<div class="column is-2 links">
					<p class="title title-font">{{ $t("PRODUCTS") }}</p>
					<p>
						<router-link to="/register">{{ $t("TRADE") }}</router-link>
					</p>
					<p>
						<a href="/ai">{{ $t("AI") }}</a>
					</p>
					<p>
						<a href="/watches">{{ $t("WATCHES") }}</a>
					</p>
					<p>
						<router-link to="/wallet">{{ $t("WALLET") }}</router-link>
					</p>
					<p>
						<a href="/insights">{{ $t("AI_INSIGHTS") }}</a>
					</p>
					<p class="title title-font mt-12">{{ $t("BLOCKCHAIN") }}</p>
					<p>
						<router-link to="/stats">{{
							$t("PROTOCOL_STATISTICS")
						}}</router-link>
					</p>
					<p>
						<router-link to="/scan">{{ $t("SIDECHAIN_EXPLORER") }}</router-link>
					</p>
				</div>
				<div class="column is-2 links">
					<p class="title title-font" v-html="$t('MPH_TOKEN')" />
					<p>
						<a :href="$t('ABOUT_MPH_SUPPORT_LINK')" v-html="$t('ABOUT_MPH')" />
					</p>
					<p>
						<a :href="$t('TOKEN_ECONOMICS_SUPPORT_LINK')">{{
							$t("TOKEN_ECONOMICS")
						}}</a>
					</p>
					<p>
						<a
							href="https://v2.info.uniswap.org/token/0x6369c3dadfc00054a42ba8b2c09c48131dd4aa38"
							target="_blank"
							rel="noopener noreferrer"
							v-html="$t('MPH_ON_UNISWAP')"
						/>
					</p>
					<p>
						<a
							href="https://www.gate.io/trade/MPH_USDT"
							target="_blank"
							rel="noopener noreferrer"
							v-html="$t('MPH_ON_GATE')"
						/>
					</p>
					<p>
						<a
							href="https://coinmarketcap.com/currencies/morpher/"
							target="_blank"
							rel="noopener noreferrer"
							>{{ $t("COINMAKRETCAP") }}</a
						>
					</p>
					<p class="title title-font mt-12">{{ $t("LEARN") }}</p>
					<!-- <p>
						<router-link to="/whats-new">{{
							$t("CHANGELOG_TITLE")
						}}</router-link>
					</p> -->
					<p>
						<a href="/blog">{{ $t("BLOG") }}</a>
					</p>
					<div class="whitepaper">
						<a :href="getWhitepaperLink() || '/docs/morpher_whitepaper.pdf'">
							{{ $t("WHITEPAPER") }}
						</a>
						<div class="whitepaper-links">
							<a href="/docs/morpher_whitepaper.pdf"> English </a>
							|
							<a href="/docs/morpher_whitepaper_russian.pdf"> Pусский </a>
							|
							<a href="/docs/morpher_whitepaper_chinese.pdf"> 普通话 </a>
						</div>
					</div>
				</div>
				<div class="column is-2 links">
					<p class="title title-font">{{ $t("COMPANY") }}</p>
					<p>
						<router-link to="/about">{{ $t("ABOUT_US") }}</router-link>
					</p>
					<p>
						<router-link to="/bug-bounty">{{ $t("BUG_BOUNTY") }}</router-link>
					</p>
					<!-- <p>
						<router-link to="/affiliates">{{
							$t("AFFILIATE_PROGRAM")
						}}</router-link>
					</p> -->
					<p class="title title-font mt-12">{{ $t("SUPPORT") }}</p>
					<p>
						<a href="https://support.morpher.com">{{ $t("HELP_CENTER") }}</a>
					</p>
					<p>
						<a href="mailto:contact@help.morpher.com">{{ $t("CONTACT_US") }}</a>
					</p>
					<p>
						<a :href="$t('GETTING_STARTED_SUPPORT_LINK')">{{
							$t("GETTING_STARTED")
						}}</a>
					</p>
					<p>
						<a :href="$t('ACCOUNT_AND_LOGIN_SUPPORT_LINK')">{{
							$t("ACCOUNT_AND_LOGIN")
						}}</a>
					</p>
					<p>
						<a :href="$t('SUPPORTED_COUNTRIES_SUPPORT_LINK')">{{
							$t("SUPPORTED_COUNTRIES")
						}}</a>
					</p>
				</div>
				<div class="column is-2 links">
					<p class="title title-font">
						{{ $t("converter.CRYPTO_CONVERTER") }}
					</p>
					<p>
						<a href="https://www.morpher.com/converter/cake/usd">{{
							$t("converter.CAKE_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/xmr/usd">{{
							$t("converter.XMR_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/avax/usd">{{
							$t("converter.AVAX_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/ltc/usd">{{
							$t("converter.LTC_USD")
						}}</a>
					</p>
					<p>
						<a
							href="https://www.morpher.com/converter/bat/usd"
							>{{ $t("converter.BAT_USD") }}</a
						>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/bch/usd">{{
							$t("converter.BCH_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/sol/usd">{{
							$t("converter.SOL_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/trx/usd">{{
							$t("converter.TRX_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/link/usd">{{
							$t("converter.LINK_USD")
						}}</a>
					</p>
					<p>
						<a href="https://www.morpher.com/converter/dash/usd">{{
							$t("converter.DASH_USD")
						}}</a>
					</p>
				</div>
				<div class="column is-hidden-touch is-hidden-desktop-only" />
				<div class="column is-3">
					<!-- Signup Form -->
					<p class="title title-font is-5">{{ $t("DISCOVER_TRADING_EDGE") }}</p>
					<GetStartedButton
						:airdropPage="airdropPage"
						:fromFooter="true"
						class="mb-min"
						:customText="isUserLoggedInTradingApp() ? 'open_morpher' : ''"
						:userAlreadyLoggedIn="isUserLoggedInTradingApp()"
						:referredBy="referredBy"
						:customInvite="customInvite"
					/>
					<!-- Company Socials -->
					<p class="is-size-18 has-text-weight-medium has-font-rubik">
						{{ $t("FOLLOW_US") }}
					</p>
					<div class="social-links">
						<a
							href="https://twitter.com/morpher_io"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher Twitter"
							title="Twitter"
						>
							<b-icon icon="fab fa-twitter" pack="fab" />
						</a>
						<!-- <a
							href="https://discord.gg/CBxuAhPSCj"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher Discord"
							title="Discord"
						>
							<b-icon icon="fab fa-discord" pack="fab" />
						</a> -->
						<!-- <a
							href="https://vk.com/morpher"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher VK"
							title="VK"
						>
							<b-icon icon="fab fa-vk" pack="fab" />
						</a> -->
						<a
							href="https://www.linkedin.com/company/morpher"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher LinkedIn"
							title="LinkedIn"
						>
							<b-icon icon="fab fa-linkedin-in" pack="fab" />
						</a>
						<a
							href="https://github.com/Morpher-io/"
							target="_blank"
							rel="noopener noreferrer"
							alt="Morpher Github"
							title="Github"
						>
							<b-icon icon="fab fa-github" pack="fab" />
						</a>
					</div>
				</div>
			</div>

			<!-- Language -->
			<div class="lang-select">
				<language-selector v-if="!isInviteRoute()" />
			</div>

			<!-- Legal Policies -->
			<div
				class="columns is-multiline policies is-size-8 is-mobile has-text-weight-medium"
				:class="{ 'empty-footer-policies': emptyFooter }"
			>
				<!-- <router-link class="column is-narrow" to="/disclosures">{{$t('disclosures')}}</router-link> -->
				<router-link class="column is-narrow" to="/referral-terms-conditions">
					{{ $t("referral_terms") }}
				</router-link>
				<router-link class="column is-narrow" to="/privacy">
					{{ $t("privacy_policy") }}
				</router-link>
				<router-link class="column is-narrow" to="/cookie-policy">
					{{ $t("cookie_policy") }}
				</router-link>
				<router-link class="column is-narrow" to="/terms-of-use">
					{{ $t("terms_of_use") }}
				</router-link>
				<router-link class="column is-narrow" to="/disclaimer">
					{{ $t("disclaimer") }}
				</router-link>
				<router-link class="column is-narrow" to="/impressum">
					{{ $t("impressum") }}
				</router-link>
			</div>
			<!-- Copyright -->
			<p class="is-size-8 copyright-text">
				{{ $t("copyright") }} © {{ new Date().getFullYear() }}
				{{ $t("all_rights") }}
				{{ "🎡 Heinestrasse 21/4, Vienna, Austria 1020" }}
			</p>
			<hr v-show="disclaimerContent > 0" style="margin: 0.5rem" />

			<!-- Disclaimers -->
			<div
				v-show="disclaimerContent > 0"
				class="columns is-centered has-text-left disclaimers"
			>
				<!-- Use disclaimerContent integer to choose which disclaimer text should be shown -->
				<!-- Default Disclaimers -->
				<div v-show="disclaimerContent === 1" class="column help">
					<p>
						<b>{{ $t("VIRTUAL_FUTURES") }}:</b>
						<span v-html="$t('VIRTUAL_FUTURES_DESC')" />
					</p>
					<p>
						<b>{{ $t("PORTFOLIO_VALUE") }}:</b>
						{{ $t("PORTFOLIO_VALUE_DESC") }}
					</p>
					<p>
						<b> {{ $t("CURRENCY_RISK") }}:</b>
						{{ $t("CURRENCY_RISK_DESC") }}
					</p>
					<p>
						<b>{{ $t("NO_COUNTERPARTY") }}:</b>
						{{ $t("NO_COUNTERPARTY_DESC") }}
					</p>
					<p>
						<b>{{ $t("SUPPORTED_MARKETS") }}:</b>
						{{ $t("SUPPORTED_MARKETS_DESC") }}
					</p>
					<!-- <p>
                        <b-icon icon="fas fa-info" pack="fas" size="is-small" />
                        <router-link to="/referral-terms-conditions" v-text="$t('referral_terms')" style="textDecoration: underline" />
					</p>-->
				</div>
				<!-- Referral Disclaimers -->
				<div v-show="disclaimerContent === 2" class="column help">
					<p>* {{ $t("TOKEN_PRICE_DISCLAMER") }}</p>
					<p>
						<b> {{ $t("TOKEN_REWARD_VARIABILITY") }}:</b>
						{{ $t("TOKEN_REWARD_VARIABILITY_DESC") }}
					</p>
					<!-- <p>
						<b>{{ $t("SUPPORTED_MARKETS") }}:</b>
						{{ $t("SUPPORTED_MARKETS_DESC") }}
					</p> -->
					<p>
						<b>{{ $t("NO_CONTRACTUAL_RELATIONSHIP") }}:</b>
						{{ $t("NO_CONTRACTUAL_RELATIONSHIP_DESC") }}
						<router-link
							to="/referral-terms-conditions"
							style="textdecoration: underline"
							v-text="$t('referral_terms')"
						/>
					</p>
				</div>
				<!-- Homepage Disclaimers -->
				<div v-show="disclaimerContent === 3" class="column help">
					<p>
						<b>{{ $t("VIRTUAL_FUTURES") }}:</b>
						<span v-html="$t('VIRTUAL_FUTURES_DESC')" />
					</p>
					<!-- <p>
						<b>¹ {{ $t("TOKEN_AIRDROP") }}:</b>
						{{ $t("TOKEN_AIRDROP_DESC") }}
					</p> -->
					<p>
						<b>² {{ $t("ZERO_FEES") }}:</b>
						<span v-html="$t('ZERO_FEES_DESC')" />
					</p>
					<p>
						<b>³ {{ $t("FRACTIONAL_SHARES") }}:</b>
						{{ $t("FRACTIONAL_SHARES_DESC") }}
					</p>
					<p>
						<b>{{ $t("PORTFOLIO_VALUE") }}:</b>
						{{ $t("PORTFOLIO_VALUE_DESC") }}
					</p>
					<p>
						<b> {{ $t("CURRENCY_RISK") }}:</b>
						{{ $t("CURRENCY_RISK_DESC") }}
					</p>
					<p>
						<b>{{ $t("NO_COUNTERPARTY") }}:</b>
						{{ $t("NO_COUNTERPARTY_DESC") }}
					</p>
					<p>
						<b>{{ $t("SUPPORTED_MARKETS") }}:</b>
						{{ $t("SUPPORTED_MARKETS_DESC") }}
					</p>
					<!-- <p>
                        <b-icon icon="fas fa-info" pack="fas" size="is-small" />
                        <router-link to="/referral-terms-conditions" v-text="$t('referral_terms')" style="textDecoration: underline" />
					</p>-->
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import GetStartedButton from "@/components/partials/GetStartedButton";
import { isUserLoggedInTradingApp } from "@/utils/helpers";
import LanguageSelector from "../LanguageSelector.vue";

export default {
	name: "Footer",
	components: {
		GetStartedButton,
		LanguageSelector,
	},
	props: {
		disclaimerContent: {
			type: Number,
			required: true,
		},
		emptyFooter: {
			type: Boolean,
			required: false,
		},
		airdropPage: {
			type: Boolean,
			required: false,
		},
		referredBy: {
			type: String,
			required: false,
			default: "",
		},
		customInvite: {
			type: String,
			required: false,
			default: "",
		},
	},
	methods: {
		isUserLoggedInTradingApp,
		getWhitepaperLink() {
			const lang = this.$i18n.locale;
			if (lang === "ru") {
				return "/docs/morpher_whitepaper_russian.pdf";
			} else if (lang === "zh") {
				return "/docs/morpher_whitepaper_chinese.pdf";
			} else {
				return "/docs/morpher_whitepaper.pdf";
			}
		},
		isInviteRoute() {
			return this.$route.name === "Airdrop" && this.$route.params.custom_invite;
		},
	},
};
</script>

<style scoped>
.mt-12 {
	margin-top: 1.25rem !important;
}
.footer {
	background-color: #fbfafc;
	-webkit-box-shadow: inset 0 33px 40px -33px #e7ecef;
	-moz-box-shadow: inset 0 33px 40px -33px #e7ecef;
	box-shadow: inset 0 33px 40px -33px #e7ecef;
	padding-top: 0;
	padding-bottom: 0;
}
.footer-content {
	padding-top: 50px;
}
.links a {
	font-size: 16px;
	line-height: 2;
	font-weight: 500;
}
.links .title-font {
	font-size: 16px;
	margin-bottom: 0.5rem;
}
.footer a {
	color: #4c4c4c;
	transition-delay: 0s;
	transition-duration: 0.1s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.footer a:hover {
	color: #000;
	transition-delay: 0s;
	transition-duration: 0.1s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.disclaimers {
	padding-bottom: 30px;
}
.disclaimers p {
	margin-top: 10px;
	/* font-style: italic; */
	/* font-size: 14px; */
}
.is-size-8 {
	font-size: 14px !important;
}
.link-list .title {
	margin-bottom: 0.75rem;
}
.mb-min {
	margin-bottom: -50px;
}
.social-links {
	margin-top: 0.5rem;
}
.social-links a {
	padding-right: 10px;
}
.based-in {
	font-size: 14px;
	padding: 10px 0;
}
.policies {
	margin-top: 10px;
	margin-bottom: 0.25rem;
	/* text-decoration: underline; */
}
.empty-footer-policies {
	margin-top: 10px;
	margin-bottom: 0.25rem;
}
.policies .column {
	padding-top: 0;
	padding-bottom: 0;
}
.copyright-text {
	margin-bottom: 1rem;
}
.lang-select {
	padding-top: 40px;
}
.whitepaper .whitepaper-links a {
	font-size: 12px;
}
.whitepaper .whitepaper-links {
	margin-top: -0.5rem;
}
@media only screen and (max-width: 768px) {
	.footer-content {
		padding-top: 25px;
	}
}
</style>
